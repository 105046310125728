@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.JobDetailHero {
    width: 100%;
    height: 60dvh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* background: rgba(29, 28, 29, 0.651); */
  }
  
  .JobDetailHero:before {
    content: '';
    position: absolute;
    background: linear-gradient(to top, #11111156, #191a1a56), url('../../assets/job_details_hero.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.JobDetailHero .JobDetailHeroContent {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}

.JobDetailHero h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.JobDetailHero h1 {
    font-size: 2.5rem;
}
}