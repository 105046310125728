@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.nathan-hero {
    width: 100%;
    height: auto;
    padding-top: 3rem;
}

.nathan-hero-content h1 {
    text-align: center;
    font-weight: normal;
    font-family: 'El Messiri', sans-serif;
}


@media screen and (max-width:940px) {




}
