/* src/components/Navbar.css */
.navbar {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: auto;
  z-index: 10;
  background-color: rgb(23, 102, 121);
  z-index: 9999;
  }
  
  .navbar .navbar-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .subArrow {
    margin: auto;
    align-items: center;
    margin-bottom: 15px;
  }

  .navbar-logo {
    width: 250px;
    z-index: 99999999;

  }
  
  .navbar-logo img {
    width: 100%;
    padding: 10px;
  }

  .navbar .mobile-icon {
    display: none;
  }
  
  .navbar .nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
  }
  
  .navbar .nav-item {
    height: 60px;
  }
  
  .navbar .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
  }
  
  .navbar .dropdown {
    position: relative;
  }
  
  .navbar .dropdown-menu {
    display: none;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;    
    background: rgb(23, 102, 121);
    list-style: none;
    padding: 0;
    width: max-content;
    border-radius: 2px;
    z-index: 99999;
  }
  
  .navbar .dropdown-menu .nav-links {
    display: block;
    padding: 10px 20px;
  }
  
  .navbar .dropdown-menu .nav-links:hover {
    background: #155677;
  }
  
  .navbar .dropdown:hover .dropdown-menu {
    display: block;
  }

  .hover-underline-animation {
    display: inline-block;
    position: relative;
  }
  
  .hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    top: 50px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  


  @media screen and (max-width: 940px) {
    .navbar {
      flex-direction: column;
      height: auto;
    }

    .navbar .navbar-container {
      justify-content: space-between;
    }
  
    .hover-underline-animation::after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      top: 50px;
      bottom: 0;
      left: 0;
      background-color: #ffffff;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }


    .navbar .mobile-icon {
      display: block;
      position: absolute;
      right: 20px;
      font-size: 1.8rem;
      cursor: pointer;
      color: #fff;
      z-index: 99999999;
    }
  
    .navbar .nav-menu {
      position: absolute;
      flex-direction: column;
      width: 50%;
      height: 91dvh;
      top: 0;
      left:-100%;
      justify-content: center;
      background: var(--primary-color);
      transition: 0.5s ease-in;
      z-index: 9999999;
    }

    .navbar .dropdown-menu {
      background: rgb(36, 112, 173);
    }

  
    .navbar .nav-menu.active {
      left: 0;
    }
  
    .navbar .nav-item {
      width: 100%;
    }
  
    .navbar .nav-links {
      text-align: center;
      padding: 20px;
      width: 100%;
      display: table;
    }
  }

  @media screen and (max-width: 600px) {
    .navbar-logo {
      width: 200px;
    }

    .navbar .dropdown-menu {
      width: 90%;
    }

    .navbar .nav-menu {
      width: 70%;
    }

  }
  