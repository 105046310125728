.component3 {
    width: 100%;
    height: auto;
    position: relative;
  }
  
  .component3:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.08;
  }


.component3-content {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding-top: 3rem;
}

.c3-left {
    width: 100%;
    margin: auto;
    padding: 0 1rem;
}

.c3-left h1 {
    font-size: 1rem;
    color: var(--primary-color);
    font-family: 'El Messiri', sans-serif;
    text-align: left;
    font-weight: normal;
    letter-spacing: 3px;
  }
  
.c3-left h2 {
    font-size: 2rem;
    text-align: left;
    padding: .5rem 0;
    letter-spacing: 3px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.589);
    line-height: 2rem;
}

.c3-left p {
    text-align: justify;
    padding: .5rem 0;
    font-family: 'Roboto';
}

.c3-right {
    width: 100%;
    margin: auto;
}

.c3-right-img {
    width: 100%;
    margin: auto;
}

.c3-right-img img {
    width: 100%;
    border-radius: 5px;
    display: block;
}


.c3-btn button {
    color: white;
}

.c3-btn button:hover {
    background-color: #04768a8e;
}

.StaffingSolutionBtn {
    text-align: left;
    padding: 1rem 0;
}


@media  screen and (max-width:940px) {

    .component3-content {
        flex-wrap: wrap;
    }
    
    .c3-left {
        width: 100%;
    }
    
    .c3-right {
        width: 100%;
    }

    .c3-left h1 {
        text-align: center;
    }

    .c3-left h2 {
        text-align: center;
    }

    .c3-left h5 {
        text-align: center;
    }

    .c3-left h2 {
        font-size: 2rem;
        line-height: 2.5rem;
    }

}


@media  screen and (max-width:600px) {
    .c3-left h2 {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }

    .c3-btn {
        text-align: center;
    }
}

