@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.alliedHealth {
    width: 100%;
    height: 80vh;
    background: rgba(0, 0, 0, 0.322);
    position: relative;
  }
  
  .alliedHealth:before {
    content: '';
    position: absolute;
    background: url('../../../assets/allied_health.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.alliedHealth {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.alliedHealthContent {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
    width: 50%;
}

.alliedHealth h1 {
    font-size: 3rem;
    color: rgba(230, 238, 240, 0.877);
    font-family: 'El Messiri', sans-serif;
    text-align: center;
    padding: .5rem 0;
    font-weight: normal;
    letter-spacing: 3px;
}

.alliedHealth p {
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
}

.nursingBtn {
    text-align: center;
    margin-top: 1rem;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.alliedHealth h1 {
    font-size: 2rem;
}

.alliedHealth {
    height: 100vh;
  }

  .alliedHealthContent {
    width: 100%;
}

}

@media screen and (max-width:700px) {
    .alliedHealth {
        height: 100vh;
      }

      .alliedHealth h1 {
        font-size: 2rem;
    }

    .alliedHealthContent {
        bottom: 10%;

    }
}