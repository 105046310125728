.allied-health-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
}

.AlliedHealthImage {
    width: 100%;
    overflow: hidden;
}

.AlliedHealthImage img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    display: block;
}

.AlliedHealthText {
    text-align: center;
    width: 100%;
    padding: 1rem;
}

.AlliedHealthText h1 {
    font-size: 1.5rem;
    color: rgba(23, 102, 121, 0.877);
    font-family: 'El Messiri', sans-serif;
    text-align: left;
    font-weight: normal;
}

.AlliedHealthText h2 {
    color: rgb(4, 66, 117);
    font-weight: normal;
    font-size: 1.2rem;
    font-family: 'Roboto', sans-serif;
    text-align: left;
}

.AlliedHealthText p {
    font-size: 15px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: left;
    padding: .5rem 0;
}

.alliedHealthSectionBtn {
    text-align: left;
}

.alliedHealthSectionBtn button {
    background-color: rgb(31, 133, 192); /* Change color as needed */
    padding: .8rem 1.5rem;
    background-color: (31, 133, 192); /* Change color as needed */
    color: #ffffff;
}

/* Responsive layout for larger screens */
@media screen and (max-width: 940px) {
    .allied-health-container {
        flex-wrap: wrap;
    }

    .alliedHealthSectionBtn {
        text-align: center;
    }

    .AlliedHealthText p {        
        text-align: justify;
    }

    .AlliedHealthText h2 {
        text-align: center;
    }

    .AlliedHealthText h1 {
        text-align: center;
    }


}