@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

.sectionThreeContent {
    display: flex;
    gap: 1rem;
    justify-content: center;
    gap: 1rem;
}

  .sectionThreeImage span {
    color: #a0e6ce;
    font-size: 1.5rem;
  }

  .sectionThreeImageCaption {
    width: 75%;
    height: auto;
    margin: auto 0;
    top: 30%;
    padding: 2rem 1rem;
    position: relative;
    background-color: rgba(12, 98, 138, 0.548); 
}


.sectionThreeCaption {
    width: 100%;
    margin: auto;
    padding: 1rem;
}

.sectionThreeImage {
    width: 100%;
    margin: auto;

}

.sectionThreeImage img{
    width: 100%;
    display: block;
}


.sectionThreeContent h1 {
    font-size: 1.5rem;
    color: rgba(23, 102, 121, 0.877);
    font-family: 'El Messiri', sans-serif;
    text-align: left;
    font-weight: normal;
    letter-spacing: 3px;
    line-height: 2rem;
}


.sectionThreeCaption p {
    font-size: 15px;
    text-align: justify;
    color: rgb(126, 121, 121);
}

.sectionThreeBtn {
    margin: 1rem 0;
}


@media screen and (max-width:940px) {
    .sectionThreeContent {
        flex-wrap: wrap;
    }
    
}

@media screen and (max-width:600px)  {
    
    .sectionThreeImageCaption {
        width: 100%;
    }
    
    .sectionThreeContent h1 {
        text-align: center;
    }

    .sectionThreeBtn {
       text-align: center;
    }

    .sectionThreeCaption p {
        font-size: 16px;
    }
    
}