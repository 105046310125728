@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.jobSearchHero {
    width: 100%;
    height: 80dvh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .jobSearchHero:before {
    content: '';
    position: absolute;
    background: url('../../assets/search_jobs_hero.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  
.jobSearchHero .jobSearchHeroContent {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
    left: 5%;
}

.jobSearchHero h1 {
    font-size: 3rem;
    font-family: 'roboto', sans-serif;
    color: rgba(231, 237, 240, 0.877);
    font-weight: normal;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.jobSearchHero h1 {
    font-size: 2.5rem;
}
}