@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.about {
    width: 100%;
    height: 80dvh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .about:before {
    content: '';
    position: absolute;
    background: url('../../assets/menu-bg-about-min.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.about .about-content {
    display: block;
    position: absolute;
    bottom: 20%;
    right: 10%;
    padding: 1rem;
}

.about h1 {
  font-size: 3rem;
  font-family: 'roboto', sans-serif;
  color: rgba(231, 237, 240, 0.877);
  font-weight: normal;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.about h1 {
    font-size: 2rem;
    text-align: center;
  }

.about .about-content {
  right: 0%;
  width: 100%;
}

}