@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.about-content {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
}

.about-content-left {
    width: 100%;
    text-align: center;
    margin: auto;
}

.about-content-left img {
    width: 100%;
    display: block;
}

.about-content-right {
    width: 100%;
    margin: auto;
    padding: 0 1rem;
}

.about-content-right span {
    color: black;
}

.about-content-right h1 {
    color: rgba(23, 102, 121, 0.877);
    font-family: 'El Messiri', sans-serif;
    text-align: left;
    font-weight: normal;
    letter-spacing: 3px;
    font-size: 2rem;
    padding-top: 1rem;
}

.about-content-right p {
    font-size: 18px;
    text-align: justify;
}

@media screen and (max-width:940px) {

    .about-content {
        flex-wrap: wrap-reverse;
    }

    .about-content-left {
        width: 100vw;
    }

    .about-content-right h1 {
        text-align: center;
    }


}
