.staffing-solutions-container {
  padding: 3rem 0;
}

.staffing-solutions-content {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.text-container {
  text-align: center;
  max-width: 100%;
}

.text-container h1 {
  font-size: 1rem;
  color: var(--primary-color);
  font-family: 'El Messiri', sans-serif;
  text-align: left;
  font-weight: normal;
  letter-spacing: 2px;
}

.text-container h2 {
  font-size: 1.5rem;
  text-align: left;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.589);
  line-height: 1.5rem;
}

.text-container p {
  padding: .5rem 0;
  color: #666;
  text-align: justify;
}


.image-container {
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}


@media screen and (max-width: 940px) {
  .staffing-solutions-content {
    flex-wrap: wrap;
  }
  
}