@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.nursing {
    width: 100%;
    height: 80vh;
    background: rgba(0, 0, 0, 0.322);
    position: relative;
  }
  
  .nursing:before {
    content: '';
    position: absolute;
    background: url('../../../assets/specialties_nursing.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.nursing {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.nursingContent {
    display: block;
    position: absolute;
    bottom: 5%;
    padding: 1rem;
    width: 50%;
}

.nursing h1 {
    font-size: 3rem;
    color: rgba(230, 238, 240, 0.877);
    font-family: 'El Messiri', sans-serif;
    text-align: center;
    padding: .5rem 0;
    font-weight: normal;
    letter-spacing: 3px;
}

.nursing p {
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
}

.nursingBtn {
    text-align: center;
    margin-top: 1rem;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.nursing h1 {
    font-size: 2rem;
}

.nursing {
    height: 100vh;
  }

  .nursingContent {
    width: 100%;
}

}

@media screen and (max-width:700px) {
    .nursing {
        height: 100vh;
      }

      .nursing h1 {
        font-size: 2rem;
    }
}